import { ChangeDetectionStrategy, Component } from '@angular/core';

import { useLocalState } from '@cosmos/state';

import { CosGlobalHeaderLocalState } from './global-header.local-state';

@Component({
  selector: 'cos-global-header',
  templateUrl: './global-header.component.html',
  styleUrl: './global-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'cos-global-header',
  },
})
export class CosGlobalHeaderComponent {
  readonly state = useLocalState(CosGlobalHeaderLocalState, this);

  toggleSecondaryOpen() {
    this.state.toggleSecondaryOpen();
  }
}
