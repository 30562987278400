import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';

import type {
  CosGlobalHeaderNavigationItem,
  NavigationItem,
} from '@cosmos/types-layout';
import { CosNavigationComponent } from '@cosmos/ui-navigation';

@Component({
  selector: 'cos-global-header-nav',
  imports: [CosNavigationComponent],
  templateUrl: './global-header-nav.component.html',
  styleUrl: './global-header-nav.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'cos-global-header-nav',
  },
})
export class CosGlobalHeaderNavComponent {
  readonly navigation = input<CosGlobalHeaderNavigationItem[]>();
  readonly navigationName = input<string>();

  readonly navigationItems = computed(() => {
    const navigation = this.navigation();
    return navigation && this._mapNavigation(navigation);
  });

  private _mapNavigation(
    items: CosGlobalHeaderNavigationItem[]
  ): NavigationItem[] {
    return items.map((item) => {
      return this._mapNavigationItem(item);
    });
  }

  private _mapNavigationItem(
    item: CosGlobalHeaderNavigationItem
  ): NavigationItem {
    const badge = item.badgeValue ? { title: item.badgeValue } : undefined;
    const badgeSize = badge?.title ? `${badge?.title}`.length : 0;

    const classes = [
      'cos-global-header-nav-item',
      badgeSize >= 3 ? 'cos-global-header-nav-item--large-badge' : '',
      item.classes ? item.classes : '',
    ];

    return {
      ...item,
      type: 'item',
      classes: classes.join(' '),
      badge,
      children: undefined,
    };
  }
}
