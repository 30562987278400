import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'cos-global-header-secondary',
  imports: [],
  templateUrl: './global-header-secondary.component.html',
  styleUrl: './global-header-secondary.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'cos-global-header-secondary',
  },
})
export class CosGlobalHeaderSecondaryComponent {}
